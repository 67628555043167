import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <noscript
          aria-hidden="true"
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJSTS9Z" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
          }}
        />
        <header className="main-header wrapper">
          <h1 className="logo aligncenter">
            <a href="/">Rovi - Supositorios de glicerina</a>
          </h1>
          <a href="#" className="btn-menu" />
        </header>
        <div className="main-menu">
          <div className="wrapper">
            <div className="row">
              <nav className="main-nav">
                <ul
                  className="inline-list alignleft nice-menu nice-menu-right nice-menu-main-menu"
                  id="nice-menu-1"
                >
                  <li className="menu-218 menu-path-front first odd ">
                    <a href="/" title>
                      Inicio
                    </a>
                  </li>
                  <li className="menu-308 menu-path-node-1  even ">
                    <a href="/por-que-rovi/">Nuestra marca</a>
                  </li>
                  <li className="menu-313 menuparent  menu-path-node-2  odd ">
                    <a href="/supositorios-de-glicerina/">
                      Supositorios
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-314 menu-path-node-3 first odd ">
                        <a href="/supositorios-de-glicerina/que-son/">
                          ¿Qué son los supositorios de glicerina?
                        </a>
                      </li>
                      <li className="menu-315 menu-path-node-4  even ">
                        <a href="/supositorios-de-glicerina/origen-vegetal/">
                          La Glicerina: origen vegetal
                        </a>
                      </li>
                      <li className="menu-316 menu-path-node-5  odd last">
                        <a href="/supositorios-de-glicerina/ventajas/">
                          Ventajas
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-317 menuparent  menu-path-node-6  even ">
                    <a href="/productos/">
                      Productos
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-319 menu-path-node-7 first odd ">
                        <a href="/productos/rovi-adultos/">Adultos</a>
                      </li>
                      <li className="menu-318 menu-path-node-8  even ">
                        <a href="/productos/rovi-ninos/">Niños</a>
                      </li>
                      {/* <li className="menu-320 menu-path-node-9  odd last">
                        <a href="/productos/">Lactantes</a>
                      </li> */}
                    </ul>
                  </li>
                  <li className="menu-349 menuparent  menu-path-node-10  odd ">
                    <a href="/estrenimiento/">
                      Estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-350 menu-path-node-11 first odd ">
                        <a href="/estrenimiento/que-es/">¿Qué es?</a>
                      </li>
                      <li className="menu-351 menu-path-node-12  even ">
                        <a href="/estrenimiento/tipos/">
                          Tipos de estreñimiento
                        </a>
                      </li>
                      <li className="menu-352 menu-path-node-13  odd ">
                        <a href="/estrenimiento/causas/">
                          Causas del estreñimiento
                        </a>
                      </li>
                      <li className="menu-353 menu-path-node-14  even last">
                        <a href="/estrenimiento/mitos/">Mitos</a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-354 menuparent  menu-path-node-15  even last">
                    <a href="/ninos/">
                      Los niños y el estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-360 menuparent  menu-path-node-21 first odd ">
                        <a href="/ninos/estrenimiento-lactantes/">
                          Lactantes
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-361 menu-path-node-22 first odd ">
                            <a href="/ninos/estrenimiento-lactantes/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-362 menu-path-node-23  even ">
                            <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-363 menu-path-node-24  odd ">
                            <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-364 menu-path-node-25  even last">
                            <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-355 menuparent  menu-path-node-16  even last">
                        <a href="/ninos/estrenimiento-infantil/">
                          Niños
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-356 menu-path-node-17 first odd ">
                            <a href="/ninos/estrenimiento-infantil/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-357 menu-path-node-18  even ">
                            <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-358 menu-path-node-19  odd ">
                            <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-359 menu-path-node-20  even last">
                            <a href="/ninos/estrenimiento-infantil/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="breadcrumbs">
          <div className="wrapper">
            <div className="row">
              <ul className="inline-list l-grid-9">
                <li>
                  <a href="/">Inicio</a>
                </li>
                <li>FAQs</li>
              </ul>{" "}
            </div>
          </div>
        </div>
        <div className="main-content wrapper">
          <div className="row">
            <div className="l-grid-9">
              <div id="accordion">
                <h2>1 ¿Cómo se pone un supositorio de glicerina?</h2>
                <div>
                  <p>
                    Primero rasgue el aluminio por la abertura superior y saque
                    el supositorio. Posteriormente introduzca el supositorio
                    profundamente en el recto y reprima la evacuación lo máximo
                    posible con el fin de que el medicamento pueda ejercer su
                    acción.
                  </p>
                  <p>
                    En niños pequeños se recomienda mantener juntos los muslos
                    durante un breve espacio de tiempo.
                  </p>
                </div>
                <h2>2 ¿Cuánto tiempo tarda en hacer efecto?</h2>
                <div>
                  <p>
                    El glicerol por vía rectal no se absorbe, actúa localmente,
                    produciendo la evacuación de las heces a los 15-30 minutos
                    de la administración.
                  </p>
                </div>
                <h2>
                  3 ¿Se puede poner un supositorio de glicerina todos los días?
                </h2>
                <div>
                  <p>
                    La posología recomendada es de 1 supositorio al día. Este
                    medicamento no se debe utilizar durante más de 7 días
                    seguidos salvo criterio médico.
                  </p>
                </div>
                <h2>4 ¿Puede ser adictivo?</h2>
                <div>
                  <p>
                    De forma general, existen 2 tipos de adicciones: la adicción
                    física y la adicción psicológica.
                  </p>
                  <p>
                    Para que se produzca <strong>adicción física</strong> es
                    necesario que el producto pase a sangre y que se produzcan
                    una serie de complicados mecanismos en el organismo. El
                    supositorio de glicerina no contiene sustancias adictivas,
                    además de ejercer su acción a nivel local en el intestino y
                    no pasar a sangre, por lo que es muy poco probable que se
                    produzca una adicción a este producto.
                  </p>
                  <p>
                    Respecto a la <strong>adicción psicológica</strong>, un uso
                    controlado del producto durante un periodo de tiempo
                    adecuado muestra ausencia de sensación de dependencia, según
                    prospecto. No obstante, el estreñimiento es una situación
                    complicada en la cual se busca una solución rápida, efectiva
                    y prolongada en el tiempo, por lo cual puede existir la
                    sensación de que, si no se usa un supositorio, no se va a
                    lograr una deposición, especialmente en este tipo de
                    laxantes, de acción rápida. Esta sensación no es real, y no
                    se puede considerar adicción, sino una mala percepción del
                    producto.
                  </p>
                  <p>
                    Se ha visto que las personas con ciertos desórdenes
                    alimenticios, como anorexia o bulimia, así como en otras
                    alteraciones psicológicas o psiquiátricas, pueden aparecer
                    síntomas de adicción psicológica a este tipo de laxantes
                    como método adelgazante.&nbsp;Esta sensación es debida a la
                    patología psiquiátrica, más que al laxante.
                  </p>
                </div>
                <h2>
                  5 Si se usan los supositorios de glicerina prolongadamente
                  ¿puede perder su efecto?
                </h2>
                <div>
                  <p>
                    No se recomienda usar este medicamento más de 7 días
                    seguidos, salvo que su médico se lo indique.
                  </p>
                </div>
                <h2>6 ¿Es mejor utilizar un supositorio o un microenema?</h2>
                <div>
                  <p>
                    Tanto los supositorios como los microenemas tienen una
                    acción muy rápida y muy efectiva (minutos).
                  </p>
                  <p>
                    Los supositorios de glicerina ejercen una triple acción:
                    reblandecen las heces, producen un reflejo mecánico de
                    evacuación con aumento del peristaltismo intestinal y tienen
                    un efecto lubrificante sobre la expulsión de las heces.
                    Además, el supositorio es blando y muy maleable, de tal
                    forma que la administración, dependiendo de cuál sea el
                    caso, puede ser más cómoda.
                  </p>
                  <p>
                    El usar uno u otro formato va a depender de cada caso
                    individual o de las preferencias del propio usuario.
                  </p>
                </div>
                <h2>
                  7 ¿Qué ventajas tiene el uso de un supositorio frente a otros
                  métodos laxantes?
                </h2>
                <div>
                  <ul>
                    <li>
                      <span className="li-content">
                        Alivio eficaz del estreñimiento ocasional.
                      </span>
                    </li>
                    <li>
                      <span className="li-content">
                        Su efecto es rápido (se obtiene entre 15 y 30 minutos).
                      </span>
                    </li>
                    <li>
                      <span className="li-content">Actúa de forma local.</span>
                    </li>
                    <li>
                      <span className="li-content">No pasa a la sangre.</span>
                    </li>
                    <li>
                      <span className="li-content">Con glicerina.</span>
                    </li>
                    <li>
                      <span className="li-content">
                        Disponibles en diferentes presentaciones para adultos,
                        niños y lactantes.
                      </span>
                    </li>
                  </ul>
                </div>
                <h2>
                  8 ¿Tiene el supositorio de glicerina interacción con otros
                  medicamentos?
                </h2>
                <div>
                  <p>No interacciona con otros medicamentos.</p>
                </div>
                <h2>9 ¿Se puede utilizar en el embarazo?</h2>
                <div>
                  <p>
                    Este medicamento se utilizará, en estos casos, según
                    criterio médico.
                  </p>
                </div>
                <h2>
                  10 ¿Se puede poner un supositorio de glicerina durante la
                  lactancia?
                </h2>
                <div>
                  <p>
                    Este medicamento se utilizará, en estos casos, según
                    criterio médico.
                  </p>
                </div>
                <h2>11 ¿Cómo funciona el supositorio de glicerina?</h2>
                <div>
                  <p>
                    El glicerol es un agente osmótico y deshidratante con
                    propiedades higroscópicas y lubricantes.
                  </p>
                  <p>
                    Su mecanismo de acción es de tipo mixto ejerciendo una
                    triple acción: reblandecen las heces, producen un reflejo
                    mecánico de evacuación con aumento del peristaltismo
                    intestinal y tienen un efecto lubrificante sobre la
                    expulsión de las heces.
                  </p>
                </div>
                <h2>
                  12 ¿Puede causar hemorroides usar un supositorio de glicerina?
                </h2>
                <div>
                  <p>
                    No está descrito que el uso de supositorios pueda provocar
                    hemorroides. Sí está contraindicado su uso en pacientes con
                    procesos inflamatorios hemorroidales, por tanto, consulte
                    con su médico en estos casos.
                  </p>
                </div>
                <h2>13 ¿El supositorio de glicerina destruye la flora?</h2>
                <div>
                  <p>
                    Debido al limitado tiempo de uso de los supositorios así
                    como a la ausencia de efecto antiséptico o antibiótico, no
                    se ha demostrado efecto negativo sobre la flora intestinal
                    durante su uso.
                  </p>
                </div>
                <h2>
                  14 ¿Se puede poner un supositorio de glicerina a una persona
                  mayor?
                </h2>
                <div>
                  <p>No existe contraindicación en personas mayores.</p>
                  <p>
                    Se recomienda consultar con su médico o farmacéutico en caso
                    de duda.
                  </p>
                </div>
                <h2>
                  15 ¿Se puede poner un supositorio de glicerina a un niño?
                </h2>
                <div>
                  <p>
                    En su farmacia encontrará supositorios de glicerina para
                    niños, indicados para el alivio sintomático del
                    estreñimiento transitorio y ocasional para niños entre 2 y
                    12 años. Cada supositorio contiene 1,140 g de glicerol como
                    principio activo.
                  </p>
                  <p>
                    Se recomienda consultar con su médico o farmacéutico en caso
                    de duda.
                  </p>
                </div>
                {/* <h2>
                  16 ¿Se puede poner un supositorio de glicerina a un
                  bebé/lactante?
                </h2>
                <div>
                  <p>
                    En su farmacia encontrará supositorios de glicerina para
                    lactantes, indicados para el alivio sintomático del
                    estreñimiento transitorio y ocasional para niños menores de
                    2 años. Cada supositorio contiene 1,140 g de glicerol como
                    principio activo.
                  </p>
                  <p>
                    Se recomienda consultar con su médico o farmacéutico en caso
                    de duda.
                  </p>
                </div> */}
                <h2>16 ¿Producen los supositorios de glicerina diarrea?</h2>
                <div>
                  <p>
                    No se ha descrito la aparición de diarrea debido al uso de
                    supositorios de glcierina.
                  </p>
                </div>
                <h2>17 ¿Se absorbe la glicerina en la sangre?</h2>
                <div>
                  <p>
                    Se efecto es a nivel local y no se absorbe en la sangre.
                  </p>
                </div>
                <h2>
                  18 ¿Son dañinos o peligrosos los supositorios de glicerina?
                </h2>
                <div>
                  <p>
                    Al igual que todos los medicamentos, los supositorios de
                    glicerina pueden tener efectos adversos, aunque no todas las
                    personas lo sufran.
                  </p>
                  <p>
                    Durante el periodo de utilización de glicerol por vía rectal
                    como laxante, se han observado los siguientes efectos
                    adversos cuya frecuencia no se ha podido establecer con
                    exactitud: Irritación, escozor o picor anal.
                  </p>
                </div>
                <h2>19 ¿Se puede utilizar con un producto oral a la vez?</h2>
                <div>
                  <p>
                    Los supositorios de glicerina son efectivos por sí solos. Si
                    usted viera que no son efectivos, antes de usar cualquier
                    otro laxante, ya sea por vía oral o rectal, debe consultarlo
                    con su médico o su farmacéutico, quien evaluará si es
                    adecuada o no la combinación.
                  </p>
                  <p>
                    En cualquier caso, nunca mezcle laxantes sin antes
                    consultarlo con un profesional sanitario, ya que existen
                    distintos tipos de laxantes en el mercado, con mecanismos de
                    acción diferentes, y podría ser perjudicial para su salud.
                  </p>
                </div>
                <h2>20 ¿Se pueden partir los supositorios?</h2>
                <div>
                  <p>
                    No se recomienda partir el supositorio ya que puede perder
                    su eficacia.
                  </p>
                </div>
                <h2>21 ¿Dónde comprar?</h2>
                <div>
                  <p>
                    Los supositorios de glicerina se pueden comprar en
                    Farmacias.
                  </p>
                </div>
                <h2>22 ¿Necesito receta?</h2>
                <div>
                  <p>
                    Los supositorios de glicerina se pueden adquirir sin
                    necesidad de receta médica.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="legales">
          <nav className="wrapper">
            <ul className="inline-list aligncenter">
              <li>
                <a href="/aviso-legal/">Aviso legal</a>
              </li>
              <li>
                <a href="/politica-de-privacidad/">Política de privacidad</a>
              </li>
              <li>
                <a href="/politica-cookies/">Política de cookies</a>
              </li>
              <li>
                <a href="/mapa-del-sitio/">Mapa del sitio</a>
              </li>
              <li>
                <a href="/contacto/">Contacto</a>
              </li>
              <li>
                <a href="/preguntas-frecuentes-estrenimiento/">FAQs</a>
              </li>
              <li>
                <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">
                  Configuración de cookies
                </a>
              </li>
            </ul>{" "}
          </nav>
        </div>
        <footer className="wrapper">
          <nav className="footer-seo row">
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/por-que-rovi/">Nuestra marca</a>
                </li>
                <li className="vs-3">
                  <a href="/supositorios-de-glicerina/">Supositorios</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/supositorios-de-glicerina/que-son/">
                        ¿Qué son los supositorios de glicerina?
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/origen-vegetal/">
                        La glicerina: origen vegetal
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/ventajas/">
                        Ventajas
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/productos/">Productos</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/productos/rovi-adultos/">Adultos</a>
                    </li>
                    <li>
                      <a href="/productos/rovi-ninos/">Niños</a>
                    </li>
                    {/* <li>
                      <a href="/productos/">Lactantes</a>
                    </li> */}
                  </ul>
                </li>
                <li className="vs-3">
                  <a href="/estrenimiento/">estreñimiento</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/estrenimiento/que-es/">¿Qué es?</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/tipos/">Tipos</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/causas/">Causas</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/mitos/">Mitos</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/ninos/">Los niños y el estreñimiento</a>
                  <ul className="nivel-1">
                    <li>
                      <a href="/ninos/estrenimiento-infantil/">Niños</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-infantil/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/ninos/estrenimiento-lactantes/">Lactantes</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="copy l-grid-3 m-full-width">
              <img
                src="/sites/all/themes/custom/rovi/images/html/logo-footer.png"
                alt="logo Rovi"
              />
              <p className="vs-2">
                <strong>©JNTL Consumer Health (Spain), S.L.</strong>
              </p>
              <p className="vs-2">
                Este sitio web está publicado
                <br className="m-hidden" />
                por JNTL Consumer Health (Spain), S.L.
                <br className="m-hidden" />
                único responsable de su contenido.
                <br />
                Está dirigida a visitantes de España.
              </p>
              <p className="semi-bold vs-2">Última actualización: marzo 2024</p>
              {/* <p>
                <img
                  src="/sites/all/themes/custom/rovi/images/html/logo-anefp.png"
                  alt="logo anefp"
                  className="logo-anefp vs-3"
                />
              </p> */}
            </div>
          </nav>
        </footer>
        <div className="instructions">
          <div className="wrapper">
            <ul className="aligncenter">
              <li>
                <img src="/sites/all/themes/custom/rovi/images/html/ico-instrucciones-lea.png" />{" "}
                Lea las instrucciones
              </li>
              <li>
                <img src="/sites/all/themes/custom/rovi/images/html/ico-instrucciones-medicamento.png" />{" "}
                de este medicamento y
              </li>
              <li>
                <img src="/sites/all/themes/custom/rovi/images/html/ico-instrucciones-farmaceutico.png" />{" "}
                consulte al Farmacéutico
              </li>
            </ul>
          </div>
        </div>
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery-ui.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/picturefill.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/main.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nvar scripts_loaded = false;\nfunction setCookie(name , state, days) {\ndate = new Date();\ndate.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));\nexpiresDate = date.toGMTString();\nwindow.document.cookie = name +'='+ state +'; expires=' + expiresDate + \"; path=/\";\n}\nfunction loadScripts() {\nscripts_loaded = true;\n}\nfunction jnjAcceptCookiesPolicy( e ) {\nsetCookie('jnj-cookies-policy', 'true', 1000);\nloadScripts();\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n}\n$(document).ready(function() {\n$(document).one( 'click', '#btn-accept-cookies-box', jnjAcceptCookiesPolicy );\n$(document).one('click', '#btn-close-cookies-box', function() {\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n});\n});\n"
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/themes/custom/rovi/js/myths.js?q933yp"
        />
      </div>
    );
  }
}

export default Page;
